@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-zoom.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import './normalize.css';
@import './variables.css';
@import './flexboxgrid.css';
@import './flex.css';



*, ::before, ::after {
    box-sizing: border-box;
}

* {
    margin: 0px;
    line-height: calc(1em + 0.725rem);
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--color-background);
    color: var(--color-text);
}



.section, .fp-tableCell {
    position: relative;
}

.section-title {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    padding-bottom: 8px;
}

.section-title::before {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-primary);
}
