html[data-theme="light"] {
    --color-primary: #D32A2A;
    --color-text: hsl(222deg, 22%, 5%);
    --color-background: hsl(0deg, 0%, 100%);
    --color-blurred-background: hsla(0deg, 0%, 100%, 0.85);
    --color-secondary: hsl(333deg, 100%, 45%);
    --color-tertiary: hsl(255deg, 85%, 30%);
    --color-decorative: hsl(200deg, 75%, 65%);
    --color-muted: hsl(210deg, 55%, 92%);
    --color-muted-background: hsla(210deg, 55%, 92%, 0.8);
    --color-info: hsl(245deg, 100%, 60%);
    --color-success: hsl(160deg, 100%, 40%);
    --color-success-background: hsla(160deg, 100%, 40%, 0.1);
    --color-error: hsl(340deg, 95%, 50%);
    --color-error-background: hsla(340deg, 95%, 43%, 0.1);
    --color-alert: hsl(37deg, 100%, 50%);
    --color-alert-background: hsla(52deg, 100%, 50%, 0.25);
    --color-venn-0: hsl(190deg, 100%, 65%);
    --color-venn-1: hsl(340deg, 100%, 65%);
    --color-gray-100: hsl(225deg, 25%, 95%);
    --color-gray-200: hsl(225deg, 16%, 90%);
    --color-gray-300: hsl(225deg, 8%, 80%);
    --color-gray-400: hsl(225deg, 8%, 70%);
    --color-gray-500: hsl(225deg, 7%, 60%);
    --color-gray-600: hsl(225deg, 15%, 50%);
    --color-gray-700: hsl(225deg, 12%, 40%);
    --color-gray-900: hsl(225deg, 25%, 20%);
    --color-gray-1000: hsl(225deg, 15%, 15%);
    --color-subtle-background: hsl(225deg, 25%, 95%);
    --color-subtle-floating: hsl(0deg, 0%, 100%);
    --color-homepage-light: hsl(204deg, 67%, 85%);
    --color-homepage-dark: hsl(202deg, 71%, 90%);
    --color-homepage-bg: hsl(204deg, 67%, 85%);
    --syntax-bg: hsl(225deg, 25%, 97%);
    --syntax-highlight: hsl(225deg, 25%, 93%);
    --syntax-txt: #2A2A2A;
    --syntax-comment: #467790;
    --syntax-prop: #da0079;
    --syntax-bool: #bf00b8;
    --syntax-val: #78909C;
    --syntax-str: #651fff;
    --syntax-name: #AA00FF;
    --syntax-del: rgb(255, 85, 85);
    --syntax-regex: #3600d6;
    --syntax-fn: #3D5AFE;
    --prefers-dark: false;
}